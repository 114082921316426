<template>
  <b-overlay :show="isLoading">
    <ValidationObserver ref="form">
      <form @submit.prevent="handleVerifyRouting">
        <b-row>
          <b-col cols="12" lg="6">
            <ValidationProvider
              #default="{ errors }"
              name="Order Type"
              rules="required"
            >
              <b-form-group label="Select Order Type *">
                <vSelect
                  v-model="routePlanningForm.order_type"
                  :options="orderType"
                  placeholder="Order Type"
                  @input="getOrders()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            v-if="routePlanningForm.order_type == 'Singapore'"
            cols="12"
            lg="6"
          >
            <ValidationProvider
              #default="{ errors }"
              name="Pickup Date"
              rules="required"
            >
              <b-form-group label="Select Pickup Date *">
                <flat-pickr
                  v-model="getOrder.pickup_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    defaultDate: 'today',
                  }"
                  class="form-control"
                  :placeholder="getOrder.pickup_date"
                  @input="getOrders()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col v-else cols="12" lg="6">
            <ValidationProvider
              #default="{ errors }"
              name="Delivery Date"
              rules="required"
            >
              <b-form-group label="Select Delivery Date *">
                <flat-pickr
                  v-model="getOrder.delivery_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    defaultDate: 'today',
                  }"
                  class="form-control"
                  :placeholder="getOrder.delivery_date"
                  @input="getOrders()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <ValidationProvider
              #default="{ errors }"
              vid="orders"
              name="Orders"
              rules="required"
            >
              <b-form-group label="Select from Available Orders *">
                <vSelect
                  v-model="routePlanningForm.stops"
                  :reduce="
                    (option) => ({
                      order_id: option.id,
                      recipient_id: option.recipient.id,
                      lat: option.recipient.lat,
                      lng: option.recipient.lng,
                      area: option.recipient.address_area,
                      postal_code: option.recipient.address_postcode,
                      address: `${option.recipient.complete_address}`,
                    })
                  "
                  :options="orders"
                  multiple
                  placeholder="Select Orders"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" lg="6">
            <ValidationProvider
              #default="{ errors }"
              vid="driver_id"
              name="Driver"
              rules="required"
            >
              <b-form-group label="Select Driver to Assign *">
                <vSelect
                  v-model="routePlanningForm.driver_id"
                  :reduce="(option) => option.id"
                  :options="drivers"
                  placeholder="Select Driver"
                >
                  <template v-slot:option="option">
                    <span class="mr-1"
                      ><b-avatar
                        :src="`${$s3URL}/minidc/driver_photo/${option.driver.driver_image}`"
                    /></span>
                    {{ option.label }}
                  </template>
                </vSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <b-row v-if="routePlanningForm.stops.length > 0">
          <b-col cols="12">
            <AppCollapse :accordion="true" type="border">
              <AppCollapseItem
                v-for="(stop, index) in routePlanningForm.stops"
                :key="index"
                title=""
                :subtitle="''"
                class="mb-2"
                variant="warning"
              >
                <template #header>
                  <div class="d-flex w-100 justify-content-between">
                    <div>
                      <b-badge variant="primary" class="badge-padding">
                        {{ stop.order_id }}
                      </b-badge>
                    </div>

                    <div>
                      <b-button
                        variant="outline-danger"
                        @click="handleRemove(stop.order_id)"
                      >
                        <FeatherIcon icon="TrashIcon" />
                      </b-button>
                    </div>
                  </div>
                </template>

                <b-row>
                  <b-col>
                    <b-form-group label="Order ID">
                      <b-form-input v-model="stop.order_id" readonly />
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Area">
                      <b-form-input v-model="stop.area" readonly />
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Latitude">
                      <b-form-input v-model="stop.lat" readonly />
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group label="Longitude">
                      <b-form-input v-model="stop.lng" readonly />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group label="Address">
                      <b-form-input v-model="stop.address" readonly />
                    </b-form-group>
                  </b-col>
                </b-row>
              </AppCollapseItem>
            </AppCollapse>
          </b-col>
        </b-row>
        <div v-if="routePlanningForm.order_type == 'Singapore'">
          <b-row>
            <b-col>
              <ValidationProvider
                #default="{ errors }"
                name="Delivery Date"
                rules="required"
              >
                <b-form-group label="Select Delivery Date *">
                  <flat-pickr
                    v-model="routePlanningForm.delivery_date"
                    class="form-control"
                    placeholder="Choose Delivery Date"
                    readonly="readonly"
                    :config="{
                      minDate: getOrder.pickup_date,
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col>
            <ValidationProvider
              #default="{ errors }"
              vid="remarks"
              name="Remarks"
            >
              <b-form-group label="Enter remarks / comments">
                <b-form-textarea v-model="routePlanningForm.remarks" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center align-items-center mt-2">
          <b-button type="submit" variant="primary"> Verify Routing </b-button>
        </div>
      </form>
    </ValidationObserver>

    <b-modal
      ref="modal"
      title="Verify Routing"
      hide-footer
      no-close-on-backdrop
    >
      <b-overlay :show="isLoading">
        <AppTimeline>
          <AppTimelineItem
            v-for="(stop, index) in routePlanningForm.stops"
            :key="index"
            :title="`Order ${stop.order_id} | ${stop.area}`"
            :subtitle="stop.address"
            icon="ChevronsRightIcon"
            variant="primary"
          />
        </AppTimeline>

        <b-row class="mt-4 mb-2">
          <b-col>
            <b-button variant="primary" @click="handleSubmit">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        />
        <h3 class="mt-3">Please wait...</h3>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import vSelect from 'vue-select';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from '@validations';
import { requestError, success } from '@/common/SwalOptions';
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'CreateRouteForm',
  components: {
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    AppTimelineItem,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      response: '',
      required,
      isLoading: false,
      orderType: ['Outlet', 'Singapore', 'Distributor'],
      orders: [],
      drivers: [],
      getOrder: { delivery_date: '', pickup_date: '' },
      routePlanningForm: {
        driver_id: null,
        remarks: '',
        origin: {},
        stops: [],
      },
    };
  },
  computed: {
    isFormFilled() {
      return (
        this.routePlanningForm.driver_id &&
        this.routePlanningForm.stops.length > 0
      );
    },
  },
  created() {
    this.handlePreload();
    this.getOrder.delivery_date = new Date().toISOString().split('T')[0];
    this.getOrder.pickup_date = new Date().toISOString().split('T')[0];
  },
  methods: {
    handlePreload() {
      this.isLoading = true;
      this.getDrivers();
      this.getOrigin();
      this.isLoading = false;
    },

    handlePostload() {
      this.isLoading = true;
      this.handleResetForm();
      this.isLoading = false;
    },

    async getOrigin() {
      const { branch } = JSON.parse(localStorage.getItem('userData'));
      const origin = {
        id: branch.id,
        lat: branch.lat,
        lng: branch.lng,
        address: branch.address,
        area: branch.area,
        postal_code: branch.postal_code,
      };
      this.routePlanningForm.origin = origin;
    },

    async getOrders() {
      this.isLoading = true;
      if (this.routePlanningForm.order_type == 'Singapore') {
        this.response = await this.$http.get(
          `get_orders_for_routing?order_type=Singapore&pickup_date=${this.getOrder.pickup_date}`
        );
      } else {
        this.response = await this.$http.get(
          `get_orders_for_routing?order_type=${this.routePlanningForm.order_type}&delivery_date=${this.getOrder.delivery_date}`
        );
      }
      this.orders = this.response.data.data.map((items) => ({
        ...items,
        label: `Order ${items.id} | From ${items.order_from} - ${items.order_type}`,
      }));
      this.routePlanningForm.stops = '';
      this.isLoading = false;
    },
    async getDrivers() {
      const response = await this.$http.get('get_driver_by_branch');
      if (response.data.success === false) {
        this.$swal(requestError);
        this.isLoading = false;
        return;
      }

      this.drivers = response.data.drivers.map((items) => ({
        ...items,
        label: items.name,
      }));
    },

    handleVerifyRouting() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$refs.modal.show();
        }
      });
    },

    async handleSubmit() {
      this.isLoading = true;
      if (this.routePlanningForm.order_type != 'Singapore') {
        this.routePlanningForm.delivery_date = this.getOrder.delivery_date;
      }
      const response = await this.$http.post(
        'create_route',
        this.routePlanningForm
      );
      if (response.data.status === false) {
        this.$refs.form.setErrors(response.data.errors);
        this.$refs.modal.hide();
        this.isLoading = false;
        return;
      }

      this.$swal(
        success({
          text: response.data.message,
        })
      ).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = false;
          this.$refs.modal.hide();
          this.handlePostload();
        }
        this.isLoading = false;
      });
    },

    handleRemove(id) {
      const index = this.routePlanningForm.stops.findIndex(
        (item) => item.id === id
      );
      this.routePlanningForm.stops.splice(index, 1);
    },

    handleResetForm() {
      this.routePlanningForm = {
        driver_id: null,
        remarks: '',
        origin: {},
        stops: [],
      };
      this.$refs.form.reset();
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
