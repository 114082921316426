<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <b-card>
          <CreateRouteForm />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CreateRouteForm from '@/views/pages/inside/RoutePlanning/CreateRouteForm.vue';

export default {
  name: 'CreateRoute',
  components: {
    CreateRouteForm,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
